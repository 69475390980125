<template>
    <div class="product-card bg-white relative flex flex-col gap-2" data-testid="product-box">
        <figure class="relative flex flex-col gap-4">
            <div class="relative">
                <div
                    class="relative bg-light-grey rounded-lg xl:rounded-xl aspect-[166/198] overflow-hidden safari-overflow-hidden-fix"
                >
                    <LoadingElement v-if="productLoading" class="absolute" :small="true"> </LoadingElement>
                    <ProductImage
                        v-else
                        :product-image-loading-error="productImageLoadingError"
                        :product-media="product?.cover?.media"
                        src-set-until="800"
                        :product-name="productName"
                        :class="{ 'opacity-50': !product.available }"
                        main-class="absolute inset-0 w-full h-full object-contain"
                        fallback-class="absolute left-1/2 -translate-x-1/2 w-1/2 h-full object-contain"
                        width-image="200"
                        height-image="300"
                        width-fallback="115"
                        height-fallback="24"
                        loading="lazy"
                        @product-image-error="productImageLoadingError = true"
                    >
                    </ProductImage>
                </div>
                <ProductBadge class="absolute left-0 bottom-0" :product="product" />
            </div>
            <figcaption>
                <div v-if="product?.manufacturer?.name" class="font-medium text-sm">
                    {{ product?.manufacturer?.name }}
                </div>
                <component
                    :is="isEditMode() ? 'div' : NuxtLink"
                    :to="pdpUrl"
                    :title="productName"
                    class="block hyphens-auto stretched-link"
                    data-testid="product-box-product-name-link"
                    @click="trackItemSelect"
                >
                    {{ productName }}
                </component>
                <span v-if="product?.unit?.name" class="text-medium-grey">
                    <span v-if="product?.packUnit">{{ product?.packUnit }}&nbsp;</span>
                    <span v-else>{{ product?.purchaseUnit }}&nbsp;{{ product?.unit?.shortCode }}&nbsp;</span>({{
                        getIntlFormattedPrice(product?.calculatedPrice.referencePrice?.price)
                    }}&nbsp;/&nbsp;{{ product?.calculatedPrice.referencePrice?.referenceUnit }}&nbsp;{{
                        product?.unit?.shortCode
                    }})</span
                >
                <div v-if="firstTextOption && !hasColor && !containsExchangeBox">
                    {{ firstTextOption?.name }}
                </div>
                <ProductCardPrice />
            </figcaption>
        </figure>
        <ProductVariantConfigurator
            v-if="hasColor"
            :colors-only="true"
            @change="onChange"
            @before-change="productLoading = true"
        />
        <AddToCartButton
            v-if="directToCart"
            class="btn btn-white mt-auto border-2 border-black mt-2"
            :product="product"
        />
    </div>
</template>

<script setup lang="ts">
import { useProductSearch } from '@shopware-pwa/composables-next';
import { getProductName } from '@shopware-pwa/helpers-next';
import type { Product, PropertyGroup } from '@shopware-pwa/types';
import ProductImage from '~/templates/elements/ProductImage.vue';
import { isEditMode } from '~/utils/content/magnolia';
import LoadingElement from '~/templates/elements/LoadingElement.vue';
import { getFirstTextOption, PRODUCT_CARD_ASSOCIATIONS } from '~/utils/helper/shop/product';
import { useProductEShopSetConfigurator } from '~/composables/shop/useProductEShopSetConfigurator';
import NuxtLink from '#app/components/nuxt-link';
import { usePrice } from '~/composables/shop/usePrice';

import { useExchangeBox } from '~/composables/shop/useExchangeBoxProduct';
import useItemTracking from '~/composables/tracking/useItemTracking';
import AddToCartButton from '~/templates/partials/header/AddToCartButton.vue';

const { getIntlFormattedPrice } = usePrice();
const props = defineProps<{
    product: Product;
    configurator: PropertyGroup[];
}>();

const directToCart = inject('directToCart', false);

const { product, changeVariant, configurator } = useProduct(props.product, props.configurator ?? ref([]));
await useProductEShopSetConfigurator(product);

const { search } = useProductSearch();
//
let productImageLoadingError = ref(false);
let productLoading = ref(false);

const onChange = async (changedProduct: Product) => {
    const { data: productResponse } = await useAsyncData('product' + changedProduct.id, async () => {
        return await search(changedProduct?.id ?? '', PRODUCT_CARD_ASSOCIATIONS);
    });

    productImageLoadingError.value = false;
    changeVariant(productResponse.value?.product as Product);
    pdpUrl.value = await getPdpUrlByProduct(product.value);
    productLoading.value = false;
};

const firstTextOption = getFirstTextOption(product?.value);

const hasColor = computed(() => {
    return (
        configurator?.value?.findIndex(
            group => (group.displayType === 'color' && (group.options ?? []).length > 1) ?? -1
        ) > -1
    );
});

const { trackItemSelect } = useItemTracking({ item: product });
const { containsExchangeBox } = await useExchangeBox(product.value);

const { getPdpUrlByProduct } = useMagnoliaShopPages();
const pdpUrl = ref('');
pdpUrl.value = await getPdpUrlByProduct(product.value);

const productName = computed(() => getProductName({ product: product.value }));
</script>
