<template>
    <div class="product-tile flex flex-col h-full">
        <div v-if="isEditMode()" class="editmode-info-box break-all mb-2">Product ID: {{ sku }}</div>
        <ProductCard v-if="product" class="h-full" :product="product" :configurator="configurator"></ProductCard>
    </div>
</template>

<script setup>
import { useProductSearch } from '@shopware-pwa/composables-next';
import { isEditMode } from '~/utils/content/magnolia';
import ProductCard from '~/templates/components/shop/ProductCard.vue';
import { PRODUCT_CARD_ASSOCIATIONS } from '~/utils/helper/shop/product';
const props = defineProps({
    sku: String,
    directToCart: Boolean,
});

if (props.directToCart) {
    // component enabled add to cart button, start injection on component level
    provide('directToCart', props.directToCart);
}

const { search } = useProductSearch();
let product, configurator;

const productTiles = inject('product-tiles', []);
// this will not match if mgnl has a variant configured but batch requests got the main variant as a response
const listingProduct = productTiles?.find(tile => tile.id === props.sku || tile.parentId === props.sku);

if (listingProduct) {
    product = listingProduct;
    configurator = listingProduct.extensions?.groups;
} else {
    const { data: productResponse } = await useAsyncData('product-tile' + props.sku, async () => {
        return await search(props.sku, PRODUCT_CARD_ASSOCIATIONS);
    });
    try {
        product = productResponse.value.product;
        configurator = productResponse.value.configurator;
    } catch (e) {
        console.error('could not load product', props.sku);
    }
}
</script>
