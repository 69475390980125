<template>
    <div class="product-stage grid grid-cols-1 lg:grid-cols-12">
        <!-- Image gallery / Slider -->
        <NuxtErrorBoundary>
            <ProductStageGallery></ProductStageGallery>
        </NuxtErrorBoundary>

        <div class="mt-4 lg:mt-0 lg:col-span-4 lg:col-start-8 relative">
            <!-- Product info -->
            <div class="relative" :class="{ 'animate-pulse pointer-events-none': isLoading }">
                <div class="lg:col-span-2">
                    <ProductBadge class="hidden lg:inline-flex lg:mb-4" :product="product" />
                    <div v-if="product?.manufacturer?.name" class="font-medium text-sm mb-1">
                        {{ product?.manufacturer?.name }}
                    </div>
                    <h1 class="font-normal text-2xl text-black lg:text-3xl" v-html="productName"></h1>
                    <div
                        v-if="productDataDeliveryNote"
                        class="mt-1 text-sm font-normal"
                        v-html="productDataDeliveryNote"
                    ></div>
                    <EShopSetPrice v-if="hasEShopSet" />
                    <ProductPrice v-else class="mt-1 lg:mt-2" :product="product" />
                    <ProductDeliveryScope
                        v-if="deliveryScope"
                        class="mt-4 lg:mt-8"
                        :product-delivery-scope="deliveryScope"
                    />
                    <slot name="introText"></slot>
                    <slot name="howTo"></slot>
                </div>

                <div v-if="containsExchangeBox" class="flex flex-col gap-2">
                    <ExchangeBoxVariantConfigurator
                        class="mt-6 xl:mt-10"
                        @before-change="beforeVariantChange"
                        @change="handleVariantChange"
                    />
                    <ProductExchangeBoxInformation class="mt-8">
                        <template #depositInfo>
                            <slot name="depositInfo"></slot>
                        </template>
                        <template #priceInfo>
                            <slot name="priceInfo"></slot>
                        </template>
                    </ProductExchangeBoxInformation>
                </div>
                <ProductVariantConfigurator
                    v-else
                    class="mt-6"
                    :show-option-name="true"
                    :option-button-default="true"
                    @before-change="beforeVariantChange"
                    @change="handleVariantChange"
                />

                <EShopSetConfigurator v-if="hasEShopSet" class="mt-7 xl:mt-10" />

                <div class="mt-6">
                    <div class="flex flex-col gap-4 lg:gap-6">
                        <ProductAvailability :product="product" :is-available="isProductAvailable" />
                        <CommonLabel
                            v-if="containsExchangeBox"
                            class="text-medium-grey text-sm"
                            path="BWF/shop/productDetailPage/exchangeBox/additionalVAT"
                        ></CommonLabel>
                        <div v-if="isProductAvailable" ref="addToCartButton">
                            <AddToCartButton
                                :product="product"
                                :disabled="isLoading"
                                class="w-full btn btn-blue mt-auto"
                            >
                                <div class="flex items-center leading-5">
                                    <svg-icon name="Cart" class="w-4 h-4" />
                                    <CommonLabel
                                        class="ml-2"
                                        path="BWF/shop/productDetailPage/addToCartLabel"
                                    ></CommonLabel>
                                    &nbsp;
                                    <SharedPrice :value="totalPrice" />
                                </div>
                            </AddToCartButton>
                        </div>
                    </div>
                    <ShopBenefits v-if="marketSetup?.shopBenefits" class="mt-6 lg:mt-8" />
                </div>

                <!-- Description and details -->
                <div class="mt-10 lg:col-start-1 lg:col-span-2">
                    <div v-if="description" class="product-description-and-details">
                        <h3 class="text-sm font-medium">
                            <CommonLabel path="BWF/shop/productDetailPage/productDetails"></CommonLabel>
                        </h3>
                        <div class="mt-4">
                            <div class="prose" v-html="description" />
                        </div>
                    </div>
                    <p v-if="!hasHiddenMainProduct" class="mt-4 flex gap-2 text-sm text-black">
                        <span class="font-medium">
                            <CommonLabel path="BWF/shop/productDetailPage/productNumber"></CommonLabel>
                        </span>
                        <span class="font-normal">{{ product?.productNumber }}</span>
                    </p>
                </div>

                <div
                    v-if="isProductAvailable"
                    class="fixed inset-x-0 bottom-0 p-2 lg:py-5 shadow-3xl bg-white transition-transform z-[4]"
                    :class="[showStickyAddToCartButton && !isEditMode ? 'translate-y-0' : 'translate-y-[150%]']"
                >
                    <div class="container grid grid-cols-1 lg:grid-cols-12">
                        <div class="lg:col-span-4 lg:col-start-8">
                            <AddToCartButton
                                :product="product"
                                :disabled="isLoading"
                                class="w-full btn btn-blue mt-auto"
                            >
                                <div class="flex items-center leading-5">
                                    <svg-icon name="Cart" class="w-4 h-4" />
                                    <CommonLabel
                                        class="ml-2"
                                        path="BWF/shop/productDetailPage/addToCartLabel"
                                    ></CommonLabel>
                                    &nbsp;
                                    <SharedPrice :value="totalPrice" />
                                </div>
                            </AddToCartButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Product } from '@shopware-pwa/types';
import { getProductUrl, getTranslatedProperty } from '@shopware-pwa/helpers-next';
import AddToCartButton from '~/templates/partials/header/AddToCartButton.vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import ProductBadge from '~/templates/components/shop/product/ProductBadge.vue';
import { useProductPrice } from '@shopware-pwa/composables-next';
import ShopBenefits from '~/templates/components/shop/product/ShopBenefits.vue';
import EShopSetConfigurator from '~/templates/components/shop/product/EShopSets/EShopSetConfigurator.vue';
import { useProductEShopSetConfigurator } from '~/composables/shop/useProductEShopSetConfigurator';
import ExchangeBoxVariantConfigurator from '~/templates/components/shop/product/ExchangeBoxVariantConfigurator.vue';
import { useExchangeBox } from '~/composables/shop/useExchangeBoxProduct';
import type { Ref } from 'vue';
import { isEditMode as evaluateEditMode } from '~/utils/content/magnolia';
import EShopSetPrice from '~/templates/components/shop/product/EShopSets/EShopSetPrice.vue';
import useItemTracking from '~/composables/tracking/useItemTracking';
import useMagnoliaContent from '~/composables/useMagnoliaContent';
const isEditMode = evaluateEditMode();

const { product } = useProduct();
const isLoading = ref<boolean>(false);

const { price } = useProductPrice(product);
const { replace } = useRouter();
const { query, path, fullPath } = useRoute();

const { marketSetup } = useMarketSetup();
const querySetOptions = ((query?.setOptions ?? '') as string).split(',').filter(option => option.length > 0);

const {
    eShopSet,
    hasEShopSet,
    eshopSetAvailable,
    eShopSetCurrentSelection,
    isLoadingUpdatedEShopSet,
    hasHiddenMainProduct,
} = await useProductEShopSetConfigurator(product, querySetOptions);

const { containsExchangeBox, exchangeBoxTotal } = await useExchangeBox(product);

const pdpSku = inject('pdpSku');

watch(eShopSetCurrentSelection, () => {
    // serialize selection to query
    replace({ query: { setOptions: eShopSetCurrentSelection.value.join(',') } });
});
watch(isLoadingUpdatedEShopSet, () => {
    isLoading.value = isLoadingUpdatedEShopSet.value;
});

const addToCartButton: Ref<HTMLElement | null> = ref(null);

const showStickyAddToCartButton = ref(false);

useIntersectionObserver(addToCartButton, ([{ isIntersecting }]) => {
    const positionY = addToCartButton.value?.getBoundingClientRect?.()?.y ?? 0;
    showStickyAddToCartButton.value = !isIntersecting && positionY > 0;
});
/**
 * Reactive Product Data
 */

const productName = computed(() => getTranslatedProperty(product.value.translated, 'name'));
const productDataDeliveryNote = computed(() =>
    //@ts-ignore
    getTranslatedProperty(product.value?.translated?.customFields, 'brita_product_data_delivery_note')
);
const deliveryScope = computed(() =>
    //@ts-ignore
    getTranslatedProperty(product.value?.translated?.customFields, 'brita_product_data_delivery')
);
const description = computed(() => getTranslatedProperty(product.value, 'description'));

const totalPrice = computed(() => {
    if (containsExchangeBox.value) {
        return exchangeBoxTotal.value;
    }
    if (hasEShopSet.value && eShopSet.value) {
        return eShopSet.value?.eshopSetPriceTotal;
    }
    return price?.value?.totalPrice;
});

const isProductAvailable = computed(() => (!hasEShopSet.value && product?.value?.available) || eshopSetAvailable.value);

const beforeVariantChange = async () => {
    isLoading.value = true;
};

const { pathVariable } = useMagnoliaContent();
const handleVariantChange = async (changedProduct: Product) => {
    if (changedProduct) {
        const changedPathVariable = changedProduct?.seoUrls?.[0]?.seoPathInfo
            ? getProductUrl(changedProduct)
            : `/${changedProduct.id}`;

        const path = `${fullPath.replace(pathVariable.value, '')}${changedPathVariable}`;
        // check if change
        if (path !== location.pathname) {
            // reload
            await navigateTo(path, { replace: true });
        }
        isLoading.value = false;
    }
};

const { trackItemView } = useItemTracking({ item: product });

onMounted(() => {
    trackItemView();
});
</script>

<style>
.product-stage {
    .product-variant-configurator {
        @apply gap-10;
    }

    .product-description-and-details {
        ul {
            list-style-image: url('~/assets/sprite/svg/Checkmark.svg');
        }

        li {
            @apply pl-2;
        }
    }
}
</style>
