<template>
    <div v-if="contactSuccess || isEditMode()">
        <EditableArea :content="successArea" :custom-view="FeedbackArea" />

        <div
            v-if="isEditMode() || newsletterSubmitted"
            class="relative mt-6 md:mt-10 rounded-xl overflow-hidden"
            :class="[contentToTailwindClasses({ backgroundColor })]"
        >
            <div
                v-if="isEditMode()"
                class="p-2.5 w-min font-medium rounded-br-2xl"
                :class="
                    newsletterActivated
                        ? 'editmode-info-box bg-green text-green'
                        : 'editmode-warning-box bg-red-500 text-red-500'
                "
            >
                <span v-if="newsletterActivated">[NEWSLETTER_IS_ACTIVATED]</span>
                <span v-else>[NEWSLETTER_IS_DEACTIVATED]</span>
                <span v-if="newsletterActivated && !interactionId" class="text-red-500 p-1"
                    >[INTERACTION_ID_MISSING]</span
                >
            </div>
            <DotPattern v-if="dotPattern" :variant="dotPattern"></DotPattern>
            <div
                class="relative grid gap-6 px-4 py-8 md:mx-auto md:w-10/12 md:gap-y-8 md:px-0 md:py-12 xl:grid-cols-5"
                :class="{
                    'xl:px-12 md:px-16 xl:w-full': hasImage,
                }"
            >
                <PictureElement
                    v-if="hasImage"
                    :img="image"
                    class="z-10 xl:col-span-2 xl:row-start-1 xl:col-start-4 xl:order-first"
                    :class="{ 'order-last': !imageFirst && isTabletOrMobile }"
                    img-class="object-cover aspect-4/3 rounded-xl overflow-hidden mx-auto"
                ></PictureElement>

                <div
                    ref="contentGroup"
                    class="content-group flex flex-col gap-6"
                    :class="[hasImage && isDesktop ? 'col-span-3' : 'col-span-full']"
                >
                    <HeadlineComponent
                        v-if="confirmationHeadline?.text"
                        class="mb-4 text-dark-blue font-medium text-2xl xl:text-3xl"
                        :headline="confirmationHeadline"
                    ></HeadlineComponent>
                    <p v-if="confirmationSubline" class="text-base text-black mb-6">
                        {{ confirmationSubline }}
                    </p>
                    <NewsletterBenefits v-if="hasNodes(confirmationSteps)" :benefits="confirmationSteps" />
                </div>
            </div>
        </div>
    </div>

    <form v-if="!contactSuccess || isEditMode()" class="contact-form-component" @submit.prevent="onSubmit()">
        <div class="relative grid gap-y-6 xl:responsive-gap xl:grid-cols-12">
            <div class="flex flex-col gap-4 md:gap-6 xl:col-span-5">
                <div class="font-medium text-lg mb-2 xl:mb-2">
                    <template v-if="primaryHeadline">
                        {{ primaryHeadline }}
                    </template>
                    <span v-else class="hidden xl:block">&nbsp;</span>
                </div>

                <SelectField
                    v-if="isEditMode() || selectableReasons.length > 1"
                    v-model="formState.subject"
                    :options="selectableReasons"
                    name="subject"
                    class="form-field-grey"
                    :label="contactFormLabels?.['subject'] ?? 'subject'"
                    :required="formRules?.subject?.hasOwnProperty('required')"
                    :placeholder="contactFormPlaceholder?.['subject'] ?? contactFormLabels?.['subject'] ?? 'subject'"
                    :error-message="contactFormVuelidate?.subject?.$errors?.[0]?.$message"
                    @blur="contactFormVuelidate?.subject?.$touch()"
                ></SelectField>

                <TextAreaField
                    v-model="formState.message"
                    name="message"
                    :label="contactFormLabels?.['message'] ?? 'message'"
                    :placeholder="contactFormPlaceholder?.['message'] ?? contactFormLabels?.['message'] ?? 'message'"
                    :maxlength="messageMaxLength"
                    :required="formRules?.message?.hasOwnProperty('required')"
                    :error-message="contactFormVuelidate?.message?.$errors?.[0]?.$message"
                    @blur="contactFormVuelidate?.message?.$touch()"
                ></TextAreaField>
            </div>
            <div class="flex flex-col gap-4 md:gap-6 xl:col-start-7 xl:col-span-6">
                <div class="font-medium text-lg mb-2 xl:mb-2">
                    <template v-if="secondaryHeadline">
                        {{ secondaryHeadline }}
                    </template>
                    <span v-else class="hidden xl:block">&nbsp;</span>
                </div>

                <div v-if="isEditMode() && selectableCountries.length === 0" class="flex bg-red-500 p-4 rounded-xl">
                    <span class="font-medium m-auto">[COUNTRY_IS_MISSING_IN_DIALOG]</span>
                </div>
                <div class="grid gap-4 md:gap-6 md:grid-cols-2">
                    <template v-for="(value, key) in formState" :key="key">
                        <!-- filter primary fields-->
                        <template v-if="!['message', 'subject', 'country'].includes(key)">
                            <template v-if="key === 'salutation'">
                                <SelectField
                                    v-model="formState[key]"
                                    :options="selectableSalutations"
                                    :placeholder="contactFormPlaceholder?.[key] ?? contactFormLabels?.[key] ?? key"
                                    :label="contactFormLabels?.[key] ?? key"
                                    class="form-field-grey col-start-1"
                                    :required="formRules[key]?.hasOwnProperty('required')"
                                    :error-message="contactFormVuelidate?.[key]?.$errors?.[0]?.$message"
                                    @blur="contactFormVuelidate?.[key]?.$touch()"
                                ></SelectField>
                            </template>
                            <InputField
                                v-else
                                v-model="formState[key]"
                                :type="fieldTypes[key] ?? 'text'"
                                :name="key"
                                :placeholder="contactFormPlaceholder?.[key] ?? contactFormLabels?.[key] ?? key"
                                :label="contactFormLabels?.[key] ?? key"
                                :required="formRules?.[key]?.hasOwnProperty('required')"
                                class="form-field-grey"
                                :class="{
                                    'col-start-1': ['company', 'first_name', 'salutation'].includes(key),
                                    'form-field-umbrella': ['middlename'].includes(key),
                                }"
                                :autocomplete="['middlename'].includes(key) ? 'off' : null"
                                :error-message="contactFormVuelidate?.[key]?.$errors?.[0]?.$message"
                                @blur="contactFormVuelidate?.[key]?.$touch()"
                            ></InputField>
                        </template>
                    </template>
                    <SelectField
                        v-if="isEditMode() || selectableCountries.length > 1"
                        v-model="formState.country"
                        :options="selectableCountries"
                        name="country"
                        required
                        class="form-field-grey"
                        :label="contactFormLabels?.['country'] ?? 'country'"
                        :placeholder="
                            contactFormPlaceholder?.['country'] ?? contactFormLabels?.['country'] ?? 'country'
                        "
                        @blur="contactFormVuelidate?.country?.$touch()"
                    ></SelectField>
                </div>
                <div class="prose prose-sm prose-black hyphens-auto max-w-none">
                    <CommonLabel path="/BWF/form/shared/requiredFieldLabel"></CommonLabel>
                    <div class="mt-2">
                        <CommonLabel path="/BWF/form/shared/privacyLinkLabel"> </CommonLabel>
                    </div>
                </div>
            </div>
            <div v-if="showNewsletterRegistration" class="col-span-full">
                <p class="font-medium text-sm md:text-base">{{ newsletterHeadline }}</p>
                <CheckboxField v-model="newsletter.newsletterConsent" class="mt-4 text-xs md:text-sm">
                    <CommonLabel path="/BWF/components/newsletter/newsletterConsent"></CommonLabel>
                </CheckboxField>
            </div>

            <button :disabled="deactivateButton" class="btn btn-blue place-self-start col-span-full" type="submit">
                <CommonLabel path="BWF/form/contact/label/sendButtonLabel"></CommonLabel>
            </button>
        </div>
    </form>
</template>

<script setup>
import useBritaMiddleware from '~/composables/useBritaMiddleware';
import SelectField from '~/templates/elements/form/SelectField.vue';
import TextAreaField from '~/templates/elements/form/TextAreaField.vue';
import InputField from '~/templates/elements/form/InputField.vue';
const { isDesktop, isTabletOrMobile } = useDevice();

const props = defineProps([
    'confirmationHeadline',
    'confirmationSubline',
    'confirmationSteps',
    'backgroundColor',
    'dotPattern',
    'image',
    'imageFirst',
    'successArea',
    'commonLabel',
    'selectableCountries',
    'visible',
    'required',
    'messageMaxLength',
    'primaryHeadline',
    'secondaryHeadline',
    'confirmModalArea',
    'errorModalArea',
    'newsletterHeadline',
    'newsletterActivated',
    'interactionId',
    'actionId',
]);

const { pushError, pushSuccess } = useNotifications();

const { user, isLoggedIn } = useUser();
const { contactForm, register, getDoiState } = useBritaMiddleware();
const { getLabels, getLabelByPath } = useCommonLabels();
const { currentLanguage } = useMagnoliaLanguage();

import { useVuelidate } from '@vuelidate/core';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import CheckboxField from '~/templates/elements/form/CheckboxField.vue';
import { isEditMode } from '~/utils/content/magnolia';
import FeedbackArea from '~/templates/areas/FeedbackArea.vue';
import { EditableArea } from '@magnolia/vue-editor';
import { hasNodes, containsImage } from '~/utils/helper/magnolia';
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';
import { contentToTailwindClasses } from '~/utils/helper/tailwind';
import DotPattern from '~/templates/elements/DotPattern.vue';
import NewsletterBenefits from '~/templates/components/shop/product/NewsletterBenefits.vue';
import PictureElement from '~/templates/elements/PictureElement.vue';

const hasImage = containsImage(props.image);
const contactSuccess = ref(false);
const newsletterSubmitted = ref(false);

const selectableReasons =
    props.commonLabel?.map(({ name, value }) => ({
        value: name,
        label: value,
    })) ?? [];

const selectableCountries =
    props.selectableCountries?.map(({ name, value }) => ({
        value: name,
        label: value,
    })) ?? [];

const fieldTypes = { language: 'hidden', email: 'email' };
const formState = reactive({
    subject: selectableReasons?.length === 1 ? selectableReasons?.[0]?.value : '',
    message: '',
    ...(props.visible?.includes('company') ? { company: '' } : {}),
    ...(props.visible?.includes('salutation') ? { salutation: '' } : {}),
    ...(props.visible?.includes('title') ? { title: '' } : {}),
    first_name: '',
    last_name: '',
    email: user?.value?.email ?? '',
    ...(props.visible?.includes('phone') ? { phone: '' } : {}),
    ...(props.visible?.includes('street_number') ? { street_number: '' } : {}),
    ...(props.visible?.includes('address_additional_info') ? { address_additional_info: '' } : {}),
    ...(props.visible?.includes('post_code') ? { post_code: '' } : {}),
    ...(props.visible?.includes('city') ? { city: '' } : {}),
    country: selectableCountries?.length === 1 ? selectableCountries?.[0]?.value : '',
    ...(props.visible?.includes('state') ? { state: '' } : {}),
    // TODO figure out what to do with theses ?
    language: currentLanguage.value,
    middlename: '',
});

const {
    firstNameValidators,
    lastNameValidators,
    emailValidators,
    streetNumberValidators,
    addressAdditionalValidators,
    cityValidators,
    phoneValidators,
    zipCodeValidators,
    requiredValidator,
    messageValidators,
} = await useValidation();

// merge with mgnl config
const formRules = computed(() => ({
    subject: {
        ...requiredValidator,
    },
    email: {
        ...requiredValidator,
        ...emailValidators,
    },
    last_name: {
        ...requiredValidator,
        ...lastNameValidators,
    },
    first_name: {
        ...requiredValidator,
        ...firstNameValidators,
    },
    message: {
        ...requiredValidator,
        ...messageValidators,
    },
    post_code: {
        ...(props.visible?.includes('post_code') && props.required?.includes('post_code')
            ? { ...requiredValidator }
            : {}),
        ...zipCodeValidators,
    },

    street_number: {
        ...(props.visible?.includes('street_number') && props.required?.includes('street_number')
            ? { ...requiredValidator }
            : {}),
        ...streetNumberValidators,
    },
    address_additional_info: {
        ...(props.visible?.includes('address_additional_info') && props.required?.includes('address_additional_info')
            ? { ...requiredValidator }
            : {}),
        ...addressAdditionalValidators,
    },
    city: {
        ...(props.visible?.includes('city') && props.required?.includes('city') ? { ...requiredValidator } : {}),
        ...cityValidators,
    },
    country: { ...requiredValidator },
    phone: {
        ...(props.visible?.includes('phone') && props.required?.includes('phone') ? { ...requiredValidator } : {}),
        ...phoneValidators,
    },
    ...(props.visible?.includes('salutation') && props.required?.includes('salutation')
        ? { salutation: { ...requiredValidator } }
        : {}),
    ...(props.visible?.includes('company') && props.required?.includes('company')
        ? { company: { ...requiredValidator } }
        : {}),
    ...(props.visible?.includes('state') && props.required?.includes('state')
        ? { state: { ...requiredValidator } }
        : {}),
    ...(props.visible?.includes('title') && props.required?.includes('title')
        ? { title: { ...requiredValidator } }
        : {}),
}));

// Build a flat Map of potential field labels and placeholders
const fieldLabelKeys = Object.keys(formState)
    .map(key => key)
    .filter(key => fieldTypes[key] !== 'hidden');
//
const contactFormLabels = await getLabels('/BWF/form/contact/label/', fieldLabelKeys);
const contactFormPlaceholder = await getLabels('/BWF/form/contact/placeholder/', fieldLabelKeys);
const salutationLabels = await getLabels('/BWF/form/shared/salutations/', ['not_specified', 'mr', 'mrs', 'diverse']);
const selectableSalutations = [
    ...(Object.entries(salutationLabels ?? {})?.map(([key, value]) => ({
        value: key,
        label: value,
    })) ?? []),
];

const contactFormPushLabels = await getLabels('BWF/form/contact/push/', [
    'newsletterSuccess',
    'newsletterError',
    'contactFormSuccess',
    'contactFormError',
]);

const zipCodePushLabel = await getLabelByPath('BWF/shared/middleware/errors/zipCodeError');

const newsletter = reactive({
    newsletterConsent: null,
});

const newsletterAlreadyRegistered = ref(false);
const showNewsletterRegistration = computed(() => {
    return props?.newsletterActivated && (isEditMode() || !newsletterAlreadyRegistered.value);
});

onMounted(async () => {
    if (!isEditMode() && isLoggedIn.value) {
        try {
            const doiState = await getDoiState(user.value?.email);
            newsletterAlreadyRegistered.value = doiState?.donotbulkemail === false;
        } catch (e) {
            console.error(e);
        }
    }
});
const deactivateButton = ref(false);
const contactFormVuelidate = useVuelidate(formRules, formState);
const onSubmit = async () => {
    deactivateButton.value = true;
    if (formState.salutation === '') {
        formState.salutation = selectableSalutations?.[0]?.value;
    }
    contactFormVuelidate.value.$touch();
    const valid = await contactFormVuelidate.value.$validate();
    if (!valid) {
        // show generic error or modal !?
        pushError(contactFormPushLabels?.contactFormError);
        deactivateButton.value = false;
        return;
    }
    try {
        await contactForm(formState);
        pushSuccess(contactFormPushLabels?.contactFormSuccess);
        contactSuccess.value = true;
        useContactformTracking(formState?.subject);
    } catch (e) {
        if (e.data === 'postalcode_validation_failed') {
            pushError(zipCodePushLabel);
            deactivateButton.value = false;
        } else {
            pushError(contactFormPushLabels?.contactFormError);
            deactivateButton.value = false;
        }
        return;
    }
    if (newsletter?.newsletterConsent) {
        try {
            await register(formState?.email, props?.interactionId, props?.actionId, formState?.middlename);
            newsletterSubmitted.value = true;
        } catch (e) {
            pushError(contactFormPushLabels?.newsletterError);
        }
    }
};
</script>
